import * as React from "react"
import { Layout } from "../components/layout"
import {
  container,
  intro,
  callToAction,
} from "./about.module.css"

function About () {
  return (
    <div className={container}>
      <h1 className={intro}>About Broken Window Creations</h1>
          <p className={callToAction}>
            Broken Window Creations did some stuff and things at some point.  Blah.
          </p>
    </div>
  )
}

export default function AboutPage({ data }) {
  return (
    <Layout>
      <About />
    </Layout>
  )
}
